/* SWIPER CSS */
 /* body{
	background-color: 	#f1f1f1;
 } */
.swiper {
	width: 100%;
	height: 100%;

}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	/* height: 100%; */
	object-fit: cover;
}
.swiper-button-prev {
	color: #1e6091 !important
  }
.swiper-button-next {
	color: #1e6091 !important;
	/* height: 1rem !important;
	width: 1rem !important; */
	height: 40px;
	width: 40px;

  }
.anchorTag{
	text-decoration: none;
	color : white
}
.anchorTag:hover{
	color : white
}
  
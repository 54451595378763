.swiper-button-disabled{
    display:none;   
  }
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #248252;
    opacity: 1;
   
  }
  .swiper-button-next {
      color:#2CA066;
      position: absolute;
      top:45%
  }
  .swiper-button-prev {
    color:#2CA066;
    position: absolute;
    top:45%
  }
  .swiper-pagination-bullet {
    background-color: #C4C4C4;
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;   
  }
  /* .swiper-slide.swiper-slide-active { opacity: 0; } */
  
  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {  
    margin: 2rem;  
  }  
  swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap,1.3rem);
  }
@font-face {
  font-family: "abhaya-libre-medium"; 
  src: url("/src/fonts/Abhaya_Libre/AbhayaLibre-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "alef-regular"; 
  src: url("/src/fonts/Alef/Alef-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "mulish-regular"; 
  src: url("/src/fonts/Mulish/static/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "KaiseiDecol-Regular"; 
  src: url("/src/fonts/Kaisei_Decol/KaiseiDecol-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-Regular"; 
  src: url("/src/fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-bold"; 
  src: url("/src/fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-Medium"; 
  src: url("/src/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
}
